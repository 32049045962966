<template>
  <div class="business">
    <el-row style="border-bottom:1px solid rgba(255, 255, 255, .3)" :class="keyIndex == 0?'park':keyIndex == 1?'association':'government'">
      <el-col :span="18">&nbsp;</el-col>
      <el-col :span="3">
        <div class="tabs">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            @click="selectTabs(item, index)"
            :class="item.active ? 'active' : 'unActive'"
          >
            {{ item.name }}
          </div>
        </div>
      </el-col>
      <el-col :span="3"></el-col>
      <div class="btn">
        <el-button plain @click="dialogVisible = true">{{ btn_name }}</el-button>
      </div>
    </el-row>
      <div>
        <div v-if="keyIndex == 0"><img src="@/assets/pc/images/business/park/1.png" alt=""></div>
        <div v-else-if="keyIndex == 1"><img src="@/assets/pc/images/business/association/1.png" alt=""></div>
        <div v-else><img src="@/assets/pc/images/business/government/1.png" alt=""></div>
      </div>
      <div>
        <div v-if="keyIndex == 0"><img src="@/assets/pc/images/business/park/2.png" alt=""></div>
        <div v-else-if="keyIndex == 1"><img src="@/assets/pc/images/business/association/2.png" alt=""></div>
        <div v-else><img src="@/assets/pc/images/business/government/2.png" alt=""></div>
      </div>
      <div>
        <div v-if="keyIndex == 0"><img src="@/assets/pc/images/business/park/3.png" alt=""></div>
        <div v-else-if="keyIndex == 1"><img src="@/assets/pc/images/business/association/3.png" alt=""></div>
        <div v-else><img src="@/assets/pc/images/business/government/3.png" alt=""></div>
      </div>
      <div>
        <div v-if="keyIndex == 0"><img src="@/assets/pc/images/business/park/4.png" alt=""></div>
        <div v-else-if="keyIndex == 1"><img src="@/assets/pc/images/business/association/4.png" alt=""></div>
        <div v-else><img src="@/assets/pc/images/business/government/4.png" alt=""></div>
      </div>
      <div>
        <div v-if="keyIndex == 0"><img src="@/assets/pc/images/business/park/5.png" alt=""></div>
        <div v-else-if="keyIndex == 1"><img src="@/assets/pc/images/business/association/5.png" alt=""></div>
        <div v-else><img src="@/assets/pc/images/business/government/5.png" alt=""></div>
      </div>
      <div>
        <div v-if="keyIndex == 0"><img src="@/assets/pc/images/business/park/6.png" alt=""></div>
        <div v-else-if="keyIndex == 1"><img src="@/assets/pc/images/business/association/6.png" alt=""></div>
        <div v-else><img src="@/assets/pc/images/business/government/6.png" alt=""></div>
      </div>
      <div v-if="keyIndex == 2">
        <img src="@/assets/pc/images/business/government/7.png" alt="">
      </div>
       <div>
        <div v-if="keyIndex == 0" class="btn-footer-park">
           <div class="btn-footer"><el-button plain @click="dialogVisible = true">{{ btn_name }}</el-button></div>
        </div>
        <div v-else-if="keyIndex == 1" class="btn-footer-association">
          <div class="btn-footer"><el-button plain @click="dialogVisible = true">{{ btn_name }}</el-button></div>
        </div>
        <div v-else class="btn-footer-government">
          <div class="btn-footer"><el-button plain @click="dialogVisible = true">{{ btn_name }}</el-button></div>
        </div>
      </div>
    <!-- <div class="btn-footer">
      <el-button plain @click="dialogVisible = true">{{ btn_name }}11111</el-button>
    </div> -->
    <el-dialog
      title="我要申报"
      :visible.sync="dialogVisible"
      :modal="false"
      width="30%"
    >
      <el-form :model="form" :rules="rules" ref="form" label-position="right"  class="demo-ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入您的名称"></el-input>
        </el-form-item>
        <el-form-item label="单位名称" prop="entityName">
          <el-input v-model="form.entityName" placeholder="请输入您的单位全称"></el-input>
        </el-form-item>
        <el-form-item label="职务" prop="position">
          <el-input v-model="form.position" placeholder="请输入职务"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item>
             <el-row :gutter="20">
                <el-col :span="18">
                  <el-form-item prop="code">
                   <el-input v-model="form.code" placeholder="请输入四位验证码"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-button  @click="getVerfiCode" :disabled="disabled">{{button_label}}</el-button>
                </el-col>
             </el-row>
          </el-form-item>
        <el-form-item label="业务诉求">
            <el-input type="textarea" v-model="form.remark" placeholder="请输入您的业务诉求，我们会尽快与您联系"></el-input>
        </el-form-item>
      </el-form>
      <div slot="title" style="text-align: center">
        <div style="color: rgba(0, 0, 0, 1);font-size: 18px;font-family: SourceHanSansSC-regular;">合作咨询</div>
        <div style="margin-top:12px;color: rgba(138, 138, 138, 100);font-size: 16px;font-family: SourceHanSansSC-regular;">请填写您的信息，我们将主动与您联系</div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="submit">立即提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { request } from '@/network';
export default {
  data () {
    return {
      tabs: [
        { name: '园区', active: false },
        { name: '商协会', active: false },
        { name: '政府', active: true }
      ],
      btn_name: '商务合作',
      keyIndex: 2,
      dialogVisible: false,
      form: {
        name: '',
        entityName: '',
        position: '',
        phone: '',
        code: '',
        businessDemands: ''
      },
      rules: {
        name: [ { required: true, message: '姓名不能为空', trigger: 'blur' } ],
        entityName: [ { required: true, message: '单位名称不能为空', trigger: 'blur' } ],
        phone: [ { required: true, message: '手机号不能为空', trigger: 'blur' } ],
        code: [ { required: true, message: '验证码不能为空', trigger: 'blur' } ]
      },
      button_label: '获取验证码',
      disabled: false
    };
  },
  methods: {
    selectTabs (item, index) {
      this.keyIndex = index;
      this.tabs.forEach((v, i) => {
        if (i === index) {
          v.active = true;
        } else {
          v.active = false;
        }
      });
      this.tabs.forEach((item, index) => {
        if (index === 0) {
          this.btn_name = '商务合作';
        } else if (index === 1) {
          this.btn_name = '合作咨询';
        } else {
          this.btn_name = '合作咨询';
        }
      });
    },
    getVerfiCode () {
      this.$refs.form.validateField('phone', async (validMes) => {
        if (validMes) return this.$message.error(validMes);
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/verificationByType',
          params: {
            phone: this.form.phone,
            type: 'code',
            sysType: this.$sysType
          }
        });
        if (res.code === 501) return this.$message.error(res.message);
        if (res.code !== 200) return this.$message.error('验证码发送失败');
        if (res.code === 200) {
          this.$message.success('验证码已发送，请注意查收');
          this.countdown();
        }
      });
    },
    countdown () {
      let time = 60;
      const timer = setInterval(() => {
        if (time === 0 || this.form.phone.length < 11) {
          clearInterval(timer);
          this.disabled = false;
          this.button_label = '获取验证码';
        } else {
          this.button_label = time + '秒后重试';
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    async newdeclare () {
      const { data: res } = await request({
        method: 'POST',
        url: '/pcp/policyDetail/newdeclare',
        data: {
          name: this.form.name,
          entityName: this.form.entityName,
          position: this.form.position,
          phone: this.form.phone,
          code: this.form.code,
          businessDemands: this.form.businessDemands
        }
      });
      if (res.code !== 200) return this.$message.error('提交失败');
      this.$message.success('提交成功');
      this.dialogVisible = false;
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.newdeclare();
        } else {
          this.$message.error(valid.message);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.park {
  background-image: url("~@/assets/pc/images/business/park/top.png");
  width: 100%;
  height: 500px;
  position: relative;
}
.association {
  background-image: url("~@/assets/pc/images/business/association/top.png");
  width: 100%;
  height: 500px;
  position: relative;
}
.government {
  background-image: url("~@/assets/pc/images/business/government/top.png");
  width: 100%;
  height: 500px;
  position: relative;
}
.tabs {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    cursor: pointer;
}
.active {
  padding-bottom: 25px;
  border-bottom: 1px solid #fff;
}
.unActive {
  padding-bottom: 25px;
}
.btn {
  position: absolute;
  top: 348px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 172px;
  height: 52px;
  /deep/.el-button {
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #022688;
  }
}

.btn-footer-park{
  background-image: url("~@/assets/pc/images/business/park/bottom.png");
  width: 100%;
  height: 233px;
  position: relative;
}
.btn-footer-association{
  background-image: url("~@/assets/pc/images/business/association/bottom.png");
  width: 100%;
  height: 233px;
  position: relative;
}
.btn-footer-government{
  background-image: url("~@/assets/pc/images/business/government/bottom.png");
  width: 100%;
  height: 233px;
  position: relative;
}

.btn-footer{
  position: absolute;
  bottom: 64px;
  left: 18.3%;
  width: 172px;
  height: 52px;
  /deep/.el-button {
    width: 100%;
    height: 100%;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #022688;
  }
}

</style>
